import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useWindowSize } from '../useWindowSize';
import ContactCard from './ContactCard';
import Container from './Container';

const Wrapper = styled.div`
    width: ${p => p.width < 750 ? '80%' : '40%'};
    background-color: rgba(153, 98, 173, 0.15);
    border: 2px solid #8A7992;
    color: #8A7992;
    border-radius: 8px;
    padding: 4% 8%;
    margin: 50px 0;
`;

const Privacy = () => {
    const [width] = useWindowSize();
    return (
        <Container>
            <Wrapper width={width}>
                <h1>Privacyverklaring</h1>
                <ContactCard marginTop='20px'/>
                Mannie Media hecht veel belang aan de bescherming van jouw persoonsgegevens en het respect voor jouw privacy. In deze privacyverklaring geven we graag een helder en transparant overzicht van hoe wij omgaan met jouw persoonsgegevens.
                Mannie Media houdt zich in alle gevallen aan de toepasselijke wet- en regelgeving, waaronder de Algemene Verordening Gegevensbescherming (GDPR).
                Mannie Media is verantwoordelijk voor de verwerking van persoonsgegevens zoals weergegeven in deze privacyverklaring.
                <br /><br />
                <h3>Persoonsgegevens die wij verwerken</h3>
                Mannie Media verwerkt uw persoonsgegevens doordat u gebruik maakt van onze diensten en/of omdat u deze zelf aan ons verstrekt.
                Hieronder vindt u een overzicht van de persoonsgegevens die wij verwerken:
                <ul>
                    <li>Voor- en achternaam</li>
                    <li>Geslacht</li>
                    <li>Adresgegevens</li>
                    <li>Telefoonnummer</li>
                    <li>E-mailadres</li>
                    <li>IP-adres</li>
                    <li>Overige persoonsgegevens die u actief verstrekt bijvoorbeeld door een profiel op deze website aan te maken, in correspondentie en telefonisch</li>
                    <li>Locatiegegevens</li>
                    <li>Gegevens over uw activiteiten op onze website</li>
                    <li>Internetbrowser en apparaat type</li>
                    <li>Bankrekeningnummer</li>
                </ul>
                <br />
                <h3>Bijzondere en/of gevoelige persoonsgegevens die wij verwerken</h3>
                Mannie Media verwerkt de volgende bijzondere en/of gevoelige persoonsgegevens van u:
                Gegevens van personen jonger dan 16 jaar Onze website en/of dienst heeft niet de intentie gegevens te verzamelen over websitebezoekers die jonger zijn dan
                16 jaar. Tenzij ze toestemming hebben van ouders of voogd. We kunnen echter niet controleren of een bezoeker ouder dan 16 is. Wij raden ouders dan ook aan
                betrokken te zijn bij de online activiteiten van hun kinderen, om zo te voorkomen dat er gegevens over kinderen verzameld worden zonder ouderlijke
                toestemming. Als u er van overtuigd bent dat wij zonder die toestemming persoonlijke gegevens hebben verzameld over een minderjarige, neem dan contact met
                ons op via <a href='mailto:manniemedia@outlook.be'>manniemedia@outlook.be</a>, dan verwijderen wij deze informatie.
                <br /><br />
                <h3>Met welk doel en op basis van welke grondslag wij persoonsgegevens verwerken</h3>
                Mannie Media verwerkt uw persoonsgegevens voor de volgende doelen:
                <ul>
                    <li>Het afhandelen van uw betaling</li>
                    <li>Verzenden van onze nieuwsbrief en/of reclamefolder</li>
                    <li>U te kunnen bellen of e-mailen indien dit nodig is om onze dienstverlening uit te kunnen voeren</li>
                    <li>U te informeren over wijzigingen van onze diensten en producten</li>
                    <li>Om goederen en diensten bij u af te leveren</li>
                    <li>Mannie Media verwerkt ook persoonsgegevens als wij hier wettelijk toe verplicht zijn, zoals gegevens die wij nodig hebben voor onze
                    belastingaangifte</li>
                    <li>Het informeren van actieve klanten over gelijkaardige producten via direct marketing (gerechtvaardigd belang)</li>
                    <li>Het verstrekken van informatie over mogelijke security updates van onze webtoepassingen</li>
                    <li>Het uitvoeren van de administratie in verband met onze samenwerking</li>
                </ul>
                <br />
                <h3>Hoe lang we persoonsgegevens bewaren</h3>
                Mannie Media bewaart uw persoonsgegevens niet langer dan strikt nodig is om de doelen te realiseren waarvoor uw gegevens worden
                verzameld. Als algemene regel houden we persoonsgegevens maximaal 5 jaar na het laatste gebruik bij. Cookievoorkeuren bewaren wij 7 dagen.
                <br /><br />
                <h3>Delen van persoonsgegevens met derden</h3>
                Mannie Media verkoopt uw gegevens niet aan derden en verstrekt deze uitsluitend indien dit nodig is voor de uitvoering van onze
                overeenkomst met u of om te voldoen aan een wettelijke verplichting. Met bedrijven die uw gegevens verwerken in onze opdracht, sluiten wij een
                bewerkersovereenkomst om te zorgen voor eenzelfde niveau van beveiliging en vertrouwelijkheid van uw gegevens. Mannie Media blijft
                verantwoordelijk voor deze verwerkingen.
                De gegevens die je aan ons geeft, kunnen wij aan derde partijen verstrekken indien dit noodzakelijk is voor de uitvoering van de hierboven beschreven doeleinden.
                <br /><br />
                Zo maken wij gebruik van een derde partij voor:
                <ul>
                    <li>Onze administratie, facturatie en projectopvolging</li>
                    <li>Het verzorgen van de internetomgeving (webhosting, e-mailbeheer,…)</li>
                </ul>
                Wij geven nooit persoonsgegevens door aan andere partijen waarmee we geen verwerkersovereenkomst hebben afgesloten.
                De website bevat hyperlinks naar websites van andere partijen. Het is mogelijk dat websites van andere partijen ook hyperlinks bevatten naar onze website. Mannie Media is niet verantwoordelijk voor het privacybeleid van die andere partijen en het gebruik van cookies op hun websites.
                <br /><br />
                <h3>Cookies, of vergelijkbare technieken, die wij gebruiken</h3>
                Mannie Media gebruikt alleen functionele cookies en analytische cookies. Een
                cookie is een klein tekstbestand dat bij het eerste bezoek aan deze website wordt opgeslagen op uw computer, tablet of smartphone. De cookies die wij
                gebruiken zijn noodzakelijk voor de technische werking van de website en uw gebruiksgemak. Ze zorgen ervoor dat de website naar behoren werkt. Ook kunnen wij hiermee onze website optimaliseren. U kunt zich afmelden voor cookies door uw internetbrowser zo in te
                stellen dat deze geen cookies meer opslaat. Daarnaast kunt u ook alle informatie die eerder is opgeslagen via de instellingen van uw browser verwijderen.
                Meer info vindt u in ons <Link to={'/cookie'}>cookiebeleid</Link>.
                <br /><br />
                <h3>Gegevens inzien, aanpassen of verwijderen</h3>
                U heeft het recht om uw persoonsgegevens in te zien, te corrigeren of te verwijderen. Daarnaast heeft u het recht om uw eventuele toestemming voor de
                gegevensverwerking in te trekken of bezwaar te maken tegen de verwerking van uw persoonsgegevens door Mannie Media en heeft u het
                recht op gegevensoverdraagbaarheid. Dat betekent dat u bij ons een verzoek kunt indienen om de persoonsgegevens die wij van u beschikken in een
                computerbestand naar u of een ander, door u genoemde organisatie, te sturen. U kunt een verzoek tot inzage, correctie, verwijdering, gegevensoverdraging van
                uw persoonsgegevens of verzoek tot intrekking van uw toestemming of bezwaar op de verwerking van uw persoonsgegevens sturen naar <a href='mailto:manniemedia@outlook.be'>manniemedia@outlook.be</a>. Om er zeker van te zijn dat het verzoek tot inzage door u is gedaan, vragen wij u een kopie van uw identiteitsbewijs met het verzoek
                mee te sturen. Dit ter bescherming van uw privacy. We reageren zo snel mogelijk op uw verzoek. Mannie Media -
                Manon Vande Walle wil u er tevens op wijzen dat u de mogelijkheid heeft om een klacht in te dienen bij de nationale toezichthouder.
                <br /><br />
                <h3>Hoe wij persoonsgegevens beveiligen</h3>
                Mannie Media neemt de bescherming van uw gegevens serieus en neemt passende maatregelen om misbruik, verlies, onbevoegde
                toegang, ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan. Als u de indruk heeft dat uw gegevens niet goed beveiligd zijn of er
                aanwijzingen zijn van misbruik, neem dan contact op via ons <Link to={'/contact'}>contactformulier</Link> of via <a href='mailto:manniemedia@outlook.be'>manniemedia@outlook.be</a>. We hanteren een gebruikersnaam en wachtwoorden op al onze systemen en maken daarvoor gebruik van een password manager met digitale wachtwoordkluis.
                Mannie Media kan in geen geval aansprakelijk worden gesteld voor enige directe of indirecte schade die voortvloeit uit een foutief of een onrechtmatig gebruik van jouw persoonsgegevens door een derde.
                <br /><br />
                <h3>Klachten</h3>
                Mocht je een klacht hebben over de verwerking van jouw persoonsgegevens, dan vragen wij om meteen contact met ons op te nemen. Je hebt ook steeds het recht een klacht in te dienen bij de Gegevensbeschermingsautoriteit. Dit is de toezichthoudende autoriteit op het gebied van privacybescherming, Drukpersstraat 35, 1000 Brussel, Tel +32 (0)2 274 48 00, e-mail: <a href='mailto:commission@privacycommission.be'>commission@privacycommission.be</a>.
                <br /><br />
                <h3>Wijziging privacyverklaring</h3>
                Mannie Media kan de privacyverklaring wijzigen. Mocht er een wijziging gebeuren dan zullen we hiervan melding maken. Het is aangewezen om deze privacyverklaring regelmatig na te lezen op eventuele wijzigingen.
            </Wrapper>
        </Container>
    )
}
export default Privacy;