import React, { useEffect, useRef, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import styled from 'styled-components';
import { useWindowSize } from '../useWindowSize';
import Icon from './Icon';

const cardHeight = 365;

const Item = styled(animated.div)`
    border-radius: 8px;
    width: ${p => p.cardWidth}px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    border: 2px solid #966fa9;
    background-color: rgba(153, 98, 173, 0.05) !important;
    margin: 10px;
    position: relative;
    overflow: hidden;
`;

const Image = styled.img`
    width: 100%;
    border-radius: 4px;
    height: inherit;
    background-color: transparent;
    image-rendering: -webkit-optimize-contrast;
`;

const Info = styled.div`
    overflow: hidden;
    position: absolute;
    top: ${cardHeight}px;
    max-width: 480px;
    margin: 10px;
`;

const Overlay = styled(animated.div)`
    width: 100%;
    height: ${cardHeight}px;
    top: 0;
    cursor: pointer;
    position: absolute;
    border-radius: 8px;
    display: flex;
    flew-flow: row nowrap;
    justify-content: center;
    align-items: center;
    z-index: 3;
`;

const ImageCenter = styled(animated.div)`
    width: ${p => p.cardWidth}px;
    cursor: pointer;
    position: absolute;
    border-radius: 8px;
`;

const ImageLeft = styled(animated.div)`
    width: ${p => p.cardWidth}px;
    left: 0;
    position: absolute;
    border-radius: 8px;
`;

const ImageRight = styled(animated.div)`
    width: ${p => p.cardWidth}px;
    right: 0;
    position: absolute;
    border-radius: 8px;
`;

const OverlayText = styled(animated.div)`
    color: white;
    font-weight: bold;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
`;

const OverlaySubText = styled.div`
    font-size: ${p => p.fontSize}px;
    color: white;
    font-weight: bold;
    text-align: center;
    ${p => p.uppercase && 'text-transform: uppercase'};
`;

const Alt = styled(animated.div)`
    width: 100%;
    height: ${cardHeight}px;
    background-color: transparent;
    top: 0;
    position: absolute;
    border-radius: 8px;
    display: flex;
    cursor: pointer;
    flew-flow: row nowrap;
    justify-content: center;
    align-items: center;
    z-index: 0;
`;

const AltText = styled.div`
    font-size: 26px;
    color: #8A7992;
    font-weight: bold;
    text-transform: uppercase;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
`;

const Arrow = styled.div`
    cursor: pointer;
    top: ${cardHeight / 2}px;
    position: absolute;
    z-index: 4;
    ${p => p.isLeft && 'left: 10px'};
    ${p => p.isRight && 'right: 10px'};
`;

const Quote = styled.i`
`;

const QuoteWrapper = styled.div`
    margin-bottom: 20px;
`;

const Description = styled.div`
    white-space: pre-wrap;
`;


const PortfolioItem = ({ client, id, review, description, isOpen, handleCardClick, index }) => {
    const [contentHeight, setContentHeight] = useState(0);
    const [contentWidth, setContentWidth] = useState(250);
    const [currentPhoto, setCurrentPhoto] = useState(1);
    const [importedImages, setImportedImages] = useState({});
    const [allImagesImported, setAllImagesImported] = useState(false);
    const [width] = useWindowSize();
    const [cardWidth, setCardWidth] = useState(300);

    const ref = useRef();

    const loadImage = (imageName, index) => {
        import(`../images/portfolio/${imageName}.jpg`).then(image => {
            setImportedImages(state => ({
                [index]: image,
                ...state
            }));
        }).catch(() => {
            setImportedImages(state => ({
                [index]: null,
                ...state
            }));
        });
    };

    useEffect(() => {
        if (ref?.current) {
            setContentHeight(ref.current.clientHeight)
        }
    }, [ref]);

    useEffect(() => {
        ['center', 'left', 'right'].forEach((image, index) => {
            loadImage(`${id}_${image}`, index)
        });
    }, [id]);

    useEffect(() => {
        setAllImagesImported(Object.keys(importedImages).length === 3 && Object.values(importedImages).filter(v => v).length === 3)
    }, [importedImages]);

    useEffect(() => {
        if (!isOpen) {
            setTimeout(() => {
                setCurrentPhoto(1)
            }, 250);
        }
    }, [isOpen]);

    useEffect(() => {
        if (width < 300) {
            setCardWidth(width)
        } else {
            setCardWidth(300)
        }
        if (width && width < 900) {
            setContentWidth(width - (width * 0.1))
        } else if (width > 900) {
            setContentWidth(500);
        } else {
            setContentWidth(cardWidth)
        }
    }, [width, cardWidth]);

    const containerProps = useSpring({
        width: isOpen ? `${contentWidth}px` : `${cardWidth}px`,
        height: isOpen ? `${contentHeight + cardHeight + 20}px` : ` ${cardHeight}px`,
        config: { duration: 200 }
    });

    const overlayProps = useSpring({
        backgroundColor: isOpen ? 'rgba(153, 98, 173, 0)' : 'rgba(153, 98, 173, 0.3)',
        config: { duration: 200 }
    });

    const instructionProps = useSpring({
        opacity: isOpen ? 0 : 1,
        config: { duration: 200 }
    });

    const altProps = useSpring({
        opacity: isOpen && !allImagesImported ? 1 : 0,
        config: { duration: 200 }
    });

    const centerImageProps = useSpring({
        zIndex: currentPhoto === 1 ? 2 : 1,
        opacity: currentPhoto === 1 ? 1 : 0.5,
        height: currentPhoto === 1 ? `${cardHeight}px` : `${cardHeight - 40}px`,
        top: currentPhoto === 1 ? '0px' : '20px',
        marginLeft: currentPhoto === 0 ? `${(contentWidth - cardWidth) / 2}px` : '0px',
        marginRight: currentPhoto === 2 ? `${(contentWidth - cardWidth) / 2}px` : '0px',
        config: { duration: 200 }
    });

    const leftImageProps = useSpring({
        zIndex: currentPhoto === 0 ? 2 : currentPhoto === 1 ? 1 : 0,
        opacity: currentPhoto === 0 ? 1 : currentPhoto === 1 ? 0.6 : 0.3,
        height: currentPhoto === 0 ? `${cardHeight}px` : `${cardHeight - 40}px`,
        top: currentPhoto === 0 ? '0px' : '20px',
        marginLeft: currentPhoto === 0 ? `${(contentWidth - cardWidth) / 2}px` : '0px',
        config: { duration: 200 }
    });

    const rightImageProps = useSpring({
        zIndex: currentPhoto,
        opacity: currentPhoto === 2 ? 1 : currentPhoto === 1 ? 0.6 : 0.3,
        height: currentPhoto === 2 ? `${cardHeight}px` : `${cardHeight - 40}px`,
        top: currentPhoto === 2 ? '0px' : '20px',
        marginRight: currentPhoto === 2 ? `${(contentWidth - cardWidth) / 2}px` : '0px',
        config: { duration: 200 }
    });

    const handleArrowClick = (isLeft) => {
        if (isLeft) {
            setCurrentPhoto(state => state - 1)
        } else {
            setCurrentPhoto(state => state + 1)
        }
    }

    return (
        <Item style={containerProps} isOpen={isOpen} cardWidth={cardWidth}>
            <Overlay onClick={() => handleCardClick(isOpen ? null : index)} style={overlayProps}>
                <OverlayText style={instructionProps}>
                    <OverlaySubText fontSize={26} uppercase={true}>{client}</OverlaySubText>
                    <OverlaySubText fontSize={20}>klik voor meer</OverlaySubText>
                </OverlayText>
            </Overlay>
            {importedImages[1] && allImagesImported ?
                <>
                    <ImageLeft index={1} style={leftImageProps} cardWidth={cardWidth}>
                        <Image src={importedImages[1]?.default} alt={client} cardWidth={cardWidth} />
                    </ImageLeft>
                    {currentPhoto !== 0 && isOpen &&
                        <Arrow isLeft={true} onClick={() => handleArrowClick(true)}>
                            <Icon type='chevron-left' size='2x' pointer={true} color='#966fa9' />
                        </Arrow>
                    }
                </> :
                null
            }
            {importedImages[0] ?
                <ImageCenter index={0} style={centerImageProps} cardWidth={cardWidth}>
                    <Image src={importedImages[0]?.default} alt={client} cardWidth={cardWidth} />
                </ImageCenter> :
                <Alt style={altProps}>
                    <AltText>
                        {client}
                    </AltText>
                </Alt>
            }
            {importedImages[2] && allImagesImported ?
                <>
                    <ImageRight index={2} style={rightImageProps} cardWidth={cardWidth}>
                        <Image src={importedImages[2]?.default} alt={client} cardWidth={cardWidth} />
                    </ImageRight>
                    {currentPhoto !== 2 && isOpen && importedImages[2] &&
                        <Arrow isRight={true} onClick={() => handleArrowClick(false)}>
                            <Icon type='chevron-right' size='2x' pointer={true} color='#966fa9' />
                        </Arrow>
                    }
                </> :
                null
            }
            <Info ref={ref} width={width}>
                <Description>{description}</Description>
                {review ? <QuoteWrapper><Quote>"{review}"</Quote></QuoteWrapper> : null}
            </Info>
        </Item>
    )
}


export default PortfolioItem;