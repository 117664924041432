import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import computer from '../images/logo-min.png';
import { useWindowSize } from '../useWindowSize';
import Background from './Background';
import Container from './Container';
import Icon from './Icon';
import MobileFeed from './MobileFeed';
import MobileHome from './MobileHome';

const Row = styled.div`
    display: flex;
    flex-flow: row ${p => p.noWrap ? 'nowrap' : 'wrap'};
    justify-content: center;
    width: 100%;
`;

const Who = styled.div`
    display: flex;
    background-color: ${p => !p.colored ? 'white' : 'rgba(153, 98, 173, 0.15)'};
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    position: relative;
    height: 700px;
    width: 100%;
`;

const WhoText = styled.div`
    width: 60%;
    ${p => p.isLeft && 'margin-left: 140px;'}
    text-align: left;
    color: black;
    font-size: 18px;
    z-index: 100;
    position: relative;
    :after {
        content: '';
        width: 100%;
        position: absolute;
        top: 16px;
        left: 0;
        height: 12px;
        background-color: rgba(153, 98, 173, 0.15);
        z-index: -1;
    }
`;

const LogoContainer = styled.div`
    width: 40%;
    height: 100%;
    display: flex;
    align-items: center;
    z-index: 3 !important;
`;

const WhoContainer = styled.div`
    width: 580px;
    margin-right: 140px;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
`;

const Image = styled.img`
    width: ${p => p.width};
    background-color: transparent;
    image-rendering: -webkit-optimize-contrast;
    z-index: 100;
`;

const LintItem = styled.div`
    z-index: 100;
    margin-top: 70px;
    text-align: center;
    width: 250px;
    ${p => p.marginbottom && `margin-bottom: ${p.marginbottom}px`};
`;

const LintText = styled.div`
    margin-top: 15px;
    color: black;
`;

const TextContainer = styled.div`
    display: flex;
    width: ${p => p.width > 1450 ? '50%' : '40%'};
    height: 100%;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    z-index: 100;
`;

const TopTextContainer = styled.div`
    display: flex;
    align-items: flex-start;
    width: 50%;
    height: 100%;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: 140px;
`;

const Title = styled.b`
    font-size: 80px;
    color: black;
    margin-bottom: 10px;
`;

const Lint = styled.div`
    min-height: 300px;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    overflow: hidden;
`;

const LintWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: flex-start;
    position: relative;
    overflow: hidden;
`;

const StyledLink = styled(Link)`
    color: rgb(0, 0, 0) !important;
`;

const TextTitle = styled.b`
    width: 100%;
    font-size: 20px;
`;

const Action = styled.button`
    font-family: 'Playfair Display', serif;
    width: auto;
    padding: 5px 25px;
    margin-top: 50px;
    cursor: pointer;
    font-size: 28px;
    border-radius: 5px;
    background-color: #966fa9;
    color: white;
    border: none;
    &:hover {
        opacity: 0.7;
    }
`;

const Name = styled.span`
    color: #966fa9;
`;

const Logo = styled.div`
    background-color: #F0E8F3;
    border-radius: 100% 0 0 100%;
    display: flex;
    width: 100%;
    height: 100%;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    z-index: 100;
`;

const Feed = styled.div`
    .crt-grid-col3 {
        margin: 0 auto;
    }
`;

const Home = () => {
    const [width] = useWindowSize();

    if (width < 900) {
        return <MobileHome />
    }
    return (
        <Container>
            <Row noWrap={true}>
                <Who>
                    <Background />
                    <TopTextContainer>
                        <Title>Hey, ik ben <Name>Manon</Name></Title>
                        <WhoText>
                            <TextTitle><i>Social Media Coach</i></TextTitle>
                            <br />
                            <br />
                            Op zoek naar een <b>social media expert</b> die je op een duidelijke én toegankelijke manier wegwijs kan maken in de wereld van hashtags, followers en engagement?
                        </WhoText>
                        <Link to={'/formules'}>
                            <Action>Let's talk!</Action>
                        </Link>
                    </TopTextContainer>
                    <LogoContainer>
                        <Logo>
                            <Image src={computer} alt='Mannie media' width='92%' />
                        </Logo>
                    </LogoContainer>
                </Who>
            </Row>
            <Row>
                <Lint>
                    <LintWrapper>
                        <LintItem>
                            <Icon type='tags' color='#966fa9' size='4x' />
                            <LintText>
                                Groeien op sociale media?
                                Bekijk hier alle <StyledLink to={'/formules'}>formules</StyledLink>.
                            </LintText>
                        </LintItem>
                        <LintItem>
                            <Icon type='camera-retro' color='#966fa9' size='4x' />
                            <LintText>
                                Nieuwsgierig naar wat ik zoal doe?
                                Bekijk mijn <StyledLink to={'/portfolio'}>portfolio</StyledLink> en lees enkele reviews.
                            </LintText>
                        </LintItem>
                        <LintItem marginbottom={70}>
                            <Icon type='envelope' color='#966fa9' size='4x' />
                            <LintText>
                                Meer weten?<br />
                                Contacteer mij <StyledLink to={'/contact'}>hier</StyledLink>.
                            </LintText>
                        </LintItem>
                    </LintWrapper>
                </Lint>
            </Row>
            <Row>
                {width > 1250 ?
                    <Who colored={true}>
                        <TextContainer width={width}>
                            <WhoText isLeft={true}>
                                <TextTitle><i>Wat kan ik voor jou doen?</i></TextTitle>
                                <br />
                                <br />
                                Zowel voor beginnende ondernemers als voor ervaren ondernemers zijn er verschillende coaching formules. Ben je een beginnende ondernemer, dan help ik graag bij het opzetten van je verschillende sociale media accounts en het uitwerken van een <b>consistente sociale media strategie</b>. Ben je een ondernemer die al een tijdje bezig is, dan maak ik voor jou een evaluatie van je bestaande socials en help ik je met mijn <b>tips and tricks</b> om deze verder uit te bouwen tot een social media succes! Sociale media hoeft immers geen uren per dag in beslag te nemen, als je het maar juist aanpakt. We focussen op creatieve manieren om jouw online aanwezigheid te vergroten en hierbij nieuwe, kwalitatieve volgers aan te trekken. <b>Are you in?</b>
                            </WhoText>
                        </TextContainer>
                        <WhoContainer>
                            {/* <Image src={feed} alt='Mannie media' width='70%' maxWidth={400} /> */}
                            <Feed id="curator-feed-default-feed-layout">
                                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                                <a href="https://curator.io" target="_blank" class="crt-logo crt-tag">Powered by Curator.io</a>
                            </Feed>
                        </WhoContainer>
                    </Who> :
                    <MobileFeed />
                }
            </Row>
        </Container>
    )
}
export default Home;