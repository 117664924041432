import React, { useCallback } from 'react'
import Particles from 'react-particles';
import { loadFull } from "tsparticles";
import facebook from '../images/icons/facebook.png';
import instagram from '../images/icons/instagram.png';
import tiktok from '../images/icons/tiktok.png';
import twitter from '../images/icons/twitter.png';
import messenger from '../images/icons/messenger.png';
import like from '../images/icons/like.png';
import heart from '../images/icons/heart.png';

const Background = () => {
    const particlesInit = useCallback(async engine => {
        await loadFull(engine);
    }, []);

    return (
        <Particles
            id="tsparticles"
            init={particlesInit}
            options={{
                fullScreen: {
                    enable: false,
                },
                background: {
                    color: {
                        value: 'transparent',
                    },
                },
                fpsLimit: 120,
                particles: {
                    color: {
                        value: "#966fa9",
                    },
                    collisions: {
                        enable: true,
                    },
                    move: {
                        directions: "none",
                        enable: true,
                        outModes: {
                            default: "bounce",
                        },
                        random: false,
                        speed: 1,
                        straight: false,
                    },
                    number: {
                        density: {
                            enable: true,
                            area: 800,
                        },
                        value: 50,
                    },
                    opacity: {
                        value: 0.15,
                    },
                    shape: {
                        type: "image",
                        options: {
                            image: [
                                {
                                    src: twitter,
                                    width: 24,
                                    height: 24,
                                    particles: {
                                        size: {
                                            value: 10
                                        }
                                    }
                                },
                                {
                                    src: instagram,
                                    width: 24,
                                    height: 24,
                                    particles: {
                                        size: {
                                            value: 10
                                        }
                                    }
                                },
                                {
                                    src: facebook,
                                    width: 24,
                                    height: 24,
                                    particles: {
                                        size: {
                                            value: 10
                                        }
                                    }
                                },
                                {
                                    src: tiktok,
                                    width: 24,
                                    height: 24,
                                    particles: {
                                        size: {
                                            value: 10
                                        }
                                    }
                                },
                                {
                                    src: messenger,
                                    width: 24,
                                    height: 24,
                                    particles: {
                                        size: {
                                            value: 10
                                        }
                                    }
                                },
                                {
                                    src: heart,
                                    width: 24,
                                    height: 24,
                                    particles: {
                                        size: {
                                            value: 10
                                        }
                                    }
                                },
                                {
                                    src: like,
                                    width: 24,
                                    height: 24,
                                    particles: {
                                        size: {
                                            value: 10
                                        }
                                    }
                                },
                            ]
                        }
                    },
                },
                detectRetina: true,
            }}
        />
    )
}

export default Background