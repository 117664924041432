import React from 'react';
import styled from 'styled-components';
import { useWindowSize } from '../useWindowSize';

const TitleContainer = styled.div`
    width: ${p => p.width < 500 ? '85%' : p.width < 700  ? '70%' : '375px'};
    height: 80px;
    margin: 20px auto 40px auto;
`;

const Title = styled.img`
    width: 100%;
    image-rendering: optimizequality;            
    image-rendering: -moz-crisp-edges;           
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: optimizequality; 
    -ms-interpolation-mode: bicubic; 
`;


const PageTitle = ({ src, title, fullWidth = false }) => {
    const [width] = useWindowSize();
    return (
        <TitleContainer width={width}>
            <Title src={src} alt={title} />
        </TitleContainer>
    )
}

export default PageTitle;