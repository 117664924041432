import React, { useEffect } from 'react';
import styled from 'styled-components';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import CookieConsent, { Cookies, getCookieConsentValue } from "react-cookie-consent";
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import { useWindowSize } from './useWindowSize';
import { initGA } from './helper';
import ScrollToTop from './components/ScrollToTop';

const ContentContainer = styled.div`
    min-height: ${p => `calc(${p.height ? `${p.height}px` : '100vh'} - 75px)`};
`;

const StyledLink = styled(Link)`
    color: white;
`;

// const ComingSoon = styled.div`
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     font-size: 30px;
//     width: 100%;
//     height: 100%;
//     color: #8A7992;
// `;

const App = () => {
    const [width, height] = useWindowSize();

    const handleCookieAccept = () => {
        if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
            initGA(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
        }
    }

    const handleCookieDecline = () => {
        //remove google analytics cookies
        Cookies.remove("_ga");
        Cookies.remove("_gat");
        Cookies.remove("_gid");
    }

    useEffect(() => {
        const isConsent = getCookieConsentValue();
        if (isConsent === "true") {
            handleCookieAccept();
        }
    }, []);

    return (
        // <ComingSoon>Coming soon...</ComingSoon> 
        <Router>
            <ScrollToTop />
            <ContentContainer>
                <Navigation isMobile={width < 900} height={height} />
            </ContentContainer>
            <Footer />
            <CookieConsent
                enableDeclineButton={true}
                // for development
                debug={false}
                buttonText='Ja, ik aanvaard de voorwaarden.'
                declineButtonText='Nee, liever niet.'
                onAccept={handleCookieAccept}
                onDecline={handleCookieDecline}
                expires={7}
                flipButtons={true}
                buttonStyle={{
                    backgroundColor: '#8A7992',
                    borderRadius: '5px',
                    color: 'white'
                }}
                declineButtonStyle={{
                    backgroundColor: 'transparent',
                    textDecoration: 'underline',
                    color: 'white'
                }}
                style={{
                    alignItems: 'center',
                }}
            >
                Om je een optimale surfervaring te bieden, maken wij gebruik van analytische cookies. Daarvoor vragen we je toestemming. Lees mijn <StyledLink to={'/cookies'}>cookiebeleid</StyledLink> en <StyledLink to={'/privacy'}>privacyverklaring</StyledLink> voor meer info.
            </CookieConsent>
        </Router>
    )
}

export default App;