import About from "./components/About";
import Contact from "./components/Contact";
import Cookie from "./components/Cookie";
import Disclaimer from "./components/Disclaimer";
import Formules from "./components/Formules";
import Home from "./components/Home";
import Portfolio from "./components/Portfolio";
import Privacy from "./components/Privacy";

const routes = [
    {
        url: '/',
        title: 'Home',
        component: Home,
        hidden: true

    },
    {
        url: '/about',
        title: 'About',
        component: About,
        hidden: false
    },
    {
        url: '/portfolio',
        title: 'Portfolio',
        component: Portfolio,
        hidden: false
    },
    {
        url: '/formules',
        title: 'Formules',
        component: Formules,
        hidden: false
    },
    {
        url: '/contact',
        title: 'Contact',
        component: Contact,
        hidden: false
    },
    {
        url: '/privacy',
        title: 'Privacy',
        component: Privacy,
        hidden: true
    },
    {
        url: '/cookie',
        title: 'Cookie',
        component: Cookie,
        hidden: true
    },
    {
        url: '/disclaimer',
        title: 'Disclaimer',
        component: Disclaimer,
        hidden: true
    },
];

export default routes;