import React, { useState } from 'react';
import { Switch, Route, Link, Redirect, useLocation } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';
import styled from 'styled-components';
import routes from '../app-config';
import { usePageViews } from '../usePageViews';
import Icon from './Icon';

const NavWrapper = styled.nav`
    height: 60px;
    font-size: 20px;
    display: flex;
    flex-flow: row nowrap;
    margin: 0 140px;
    justify-content: space-between;
`;

const Routes = styled.div`
    width: auto;
    height: 60px;
    font-size: 20px;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
`;

const MobileNavWrapper = styled.div`
    align-items: center;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    border-bottom: 3px solid;
    border-image: radial-gradient(circle, #e1cbe8, transparent) 1;
`;

const MobileNavIconWrapper = styled.div`
    height: 17px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    cursor: pointer;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    margin: 10px 0;
`;

const ItemsContainer = styled(animated.div)`
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    overflow: hidden;
`;

const RoutesContainer = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
`;

const StyledIcon = styled(Icon)`
    -webkit-tap-highlight-color: transparent;
`;

const NavItem = styled.div`
    margin: 10px 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    a {

        color: ${p => p.selected ? '#966fa9' : 'black'};
        font-weight: ${p => p.selected ? 'bold' : 'normal'};
        text-decoration: none;
        &:hover {
            opacity: ${p => p.selected ? 1 : 0.7};
        }
    }
`;

const NavNameItem = styled.div`
    margin: 10px 0px;
    cursor: pointer;
    font-size: 24px;
    text-align: center;
    a {
        color: #966fa9;
        font-weight: bold;
        text-decoration: none;
    }
`;

const Router = ({ isMobile }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const location = useLocation();
    usePageViews(location);

    const props = useSpring({
        to: {
            maxHeight: isMenuOpen ? 500 : 0,
        },
        from: {
            maxHeight: isMenuOpen ? 500 : 0,
        }
    });
    return (
        <>
            {isMobile ?
                <MobileNavWrapper>
                    <MobileNavIconWrapper>
                        <StyledIcon
                            type={isMenuOpen ? 'times' : 'bars'}
                            onClick={() => setIsMenuOpen(state => !state)}
                            color='#8A7992'
                        />
                    </MobileNavIconWrapper>
                    <ItemsContainer style={props}>
                        <div>
                            <NavNameItem onClick={() => setIsMenuOpen(false)}>
                                <Link to={'/'}>Mannie Media</Link>
                            </NavNameItem>
                        </div>
                        <RoutesContainer>
                            {
                                routes.filter(r => !r.hidden).map((r, i) =>
                                    <NavItem key={i} selected={r.url === location.pathname} onClick={() => setIsMenuOpen(false)}>
                                        <Link to={r.url}>{r.title}</Link>
                                    </NavItem>
                                )
                            }
                        </RoutesContainer>
                    </ItemsContainer>
                </MobileNavWrapper> :
                <NavWrapper>
                    <div>
                        <NavNameItem><Link to={'/'}>Mannie Media</Link></NavNameItem>
                    </div>
                    <Routes>
                        {
                            routes.filter(r => !r.hidden).map((r, i) =>
                                <NavItem key={i} selected={r.url === location.pathname}>
                                    <Link to={r.url}>{r.title}</Link>
                                </NavItem>
                            )
                        }
                    </Routes>
                </NavWrapper>
            }
            <Switch>
                {
                    routes.map((r, i) => <Route exact path={r.url} component={r.component} key={i} />)
                }
                <Redirect to={routes[0].url} />
            </Switch>
        </>
    )
}

export default Router;