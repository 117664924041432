import React from 'react';
import styled from 'styled-components';
import ContactCard from './ContactCard';
import { useWindowSize } from '../useWindowSize';
import Container from './Container';

const Wrapper = styled.div`
    width: ${p => p.width < 750 ? '80%' : '40%'};
    background-color: rgba(153, 98, 173, 0.15);
    border: 2px solid #8A7992;
    color: #8A7992;
    border-radius: 8px;
    padding: 4% 8%;
    margin: 50px 0;
`;

const Disclaimer = () => {
    const [width] = useWindowSize();
    return (
        <Container>
            <Wrapper width={width}>
                <h1>Disclaimer</h1>
                <ContactCard marginTop='20px'/>
                Uw gebruik van de site "manniemedia.be" houdt in dat u de volgende gebruiksvoorwaarden integraal aanvaardt.
                <h3>Intellectuele eigendomsrechten</h3>
                <div>
                    De inhoud van deze site, met inbegrip van de merken, logo’s, tekeningen, data, product- of bedrijfsnamen, teksten, beelden e.d. zijn beschermd door de intellectuele rechten en behoren toe aan Mannie Media of rechthoudende derden.
                </div>
                <h3>Links naar andere sites</h3>
                <div>
                    In bepaalde rubrieken bevat de Mannie Media-site hyperlinks naar inhoud die van derden uitgaat of naar websites die beheerd worden door derden. Wij zijn niet verantwoordelijk voor de kwaliteit en accuraatheid van de inhoud van deze websites.
                    We kunnen ook niet beschouwd worden als instantie die de inhoud van deze websites of de websites in het algemeen goedkeurt, publiceert of wettigt. Bijgevolg zijn de uitbaters van die websites de enige verantwoordelijken betreffende het naleven
                    van de wetgevingen en reglementeringen in verband met de producten en diensten die ze te koop aanbieden op hun website.
                </div>
                <h3>Beschikbaarheid van de site</h3>
                <div>
                    Hoewel wij ons inzetten om de Mannie Media-site 7 dagen op 7 en 24 uur op 24 ter beschikking te stellen, behouden wij ons het recht om op elk moment en zonder voorafgaande waarschuwing, de toegang tot de site te onderbreken om technische redenen of andere.
                    Wij behoudens ons eveneens het recht onze diensten te beëindigen. Dit zonder dat we kunnen verantwoordelijk gehouden worden voor deze onderbrekingen en de mogelijke gevolgen hiervan voor u of een derde.
                </div>
                <h3>Wet van toepassing en rechtspraak</h3>
                <div>
                    Voor elk eventueel geschil dat voortvloeit uit het gebruik van de Mannie Media-site is de Belgische wet van toepassing, en enkel de Belgische rechtbanken zijn bevoegd.
                </div>
            </Wrapper>
        </Container>
    )
}
export default Disclaimer;