import React from 'react';
import styled from 'styled-components';
import ContactCard from './ContactCard';
import { useWindowSize } from '../useWindowSize';
import Container from './Container';

const Wrapper = styled.div`
    width: ${p => p.width < 750 ? '80%' : '40%'};
    background-color: rgba(153, 98, 173, 0.15);
    border: 2px solid #8A7992;
    color: #8A7992;
    border-radius: 8px;
    padding: 4% 8%;
    margin: 50px 0;
`;

const Cookie = () => {
    const [width] = useWindowSize();
    return (
        <Container>
            <Wrapper width={width}>
                <h1>Cookies</h1>
                <ContactCard marginTop='20px'/>
                <h3>Wat zijn cookies?</h3>
                <div>
                    Cookies zijn kleine data- of tekstbestanden die door websites op je lokale computer worden geplaatst. Dergelijke cookies kunnen verschillende doeleinden hebben.
                    Een cookie bevat een unieke code die toe laat om uw browser te herkennen gedurende een bezoek aan de website of jouw gebruikservaring te verbeteren bij herhaalde bezoeken aan het platform.
                    Cookies kunnen geplaatst worden door de server van de website die je bezoekt of door een partner, waarmee de website samenwerkt. De server van de website kan alleen de cookies lezen die het zelf geplaatst heeft. Hij heeft geen toegang tot andere informatie die zich op jouw computer of mobiel apparaat bevindt.
                    De inhoud van een cookie bestaat meestal uit de naam van de server die de cookie geplaatst heeft, een vervaldatum en een unieke cijfercode.
                    Cookies maken de interactie tussen de bezoekers en de website over het algemeen gemakkelijker en sneller en helpen de bezoekers te navigeren tussen de verschillende onderdelen van de website. Cookies kunnen ook gebruikt worden om de inhoud van de website te personaliseren aan de gebruiker.
                    Meer informatie over de verschillende soorten cookies kan je vinden op <a href='https://www.allaboutcookies.org' rel='noreferrer' target='_blank'>www.allaboutcookies.org</a>.
                </div>
                <h3>Welke cookies gebruiken wij?</h3>
                <div>
                    Bij een bezoek aan de website van Mannie Media wordt u gevraagd of het gebruik van cookies accepteert of niet. Bij het weigeren zullen de analytische cookies uitgeschakeld worden.
                    Functionele cookies zijn essentieel voor de werking van de website en kunnen niet geweigerd worden.
                    <ul>
                        <li>Functionele cookies: Deze cookies zijn nodig voor de basisfunctionaliteit van de website. Ze staan in voor de technische werking van de website en dragen bij tot het gebruiksgemak van de bezoeker.</li>
                        <li>Analytische cookies: Deze cookies stellen ons in staat het gebruik van de site te analyseren, zodat we de prestatie kunnen meten en verbeteren. Hiervoor gebruikt Mannie Media Google Analytics. Deze cookies worden 7 dagen bewaard</li>
                    </ul>
                </div>
                <h3>Beheer uw cookies</h3>
                <div>
                    U kunt de cookies die Mannie Media gebruikt, uitschakelen in de mate en zoals hierna beschreven. Het verwijderen en/of weigeren van cookies kan tot gevolg hebben dat u de website niet meer optimaal kan gebruiken.
                    De werkwijze is afhankelijk van de browser die u gebruikt en kan u raadplegen via onderstaande links.
                    <ul>
                        <li>Google Chrome: <a href='https://support.google.com/chrome/answer/95647?hl=nl' rel='noreferrer' target='_blank'>Klik hier</a></li>
                        <li>Internet Explorer: <a href='https://support.microsoft.com/nl-nl/topic/cookies-verwijderen-in-internet-explorer-bca9446f-d873-78de-77ba-d42645fa52fc' rel='noreferrer' target='_blank'>Klik hier</a></li>
                        <li>Firefox/Mozilla: <a href='https://support.mozilla.org/nl/kb/navigatie-zoek-en-downloadgeschiedenis-verwijderen' rel='noreferrer' target='_blank'>Klik hier</a></li>
                        <li>Safari: <a href='https://support.apple.com/nl-nl/guide/safari/sfri11471/mac' rel='noreferrer' target='_blank'>Klik hier</a></li>
                        <li>Opera: <a href='https://help.opera.com/en/latest/web-preferences/#cookies' rel='noreferrer' target='_blank'>Klik hier</a></li>
                    </ul>
                </div>
            </Wrapper>
        </Container>
    )
}
export default Cookie;