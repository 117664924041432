import React, { useState } from 'react';
import styled from 'styled-components';
import FormuleCard from './FormuleCard';
import formules from '../formules.json';
import PageTitle from './PageTitle';
import title from '../images/formules_title-min.png';
import Container from './Container';
import { useWindowSize } from '../useWindowSize';

const CardContainer = styled.div`
    width: ${p => p.width <= 400 ? '100%' : '90%'};
    display: flex;
    justify-content: space-around;
    flex-flow: row wrap;
`;

const Formules = () => {
    const [width] = useWindowSize();
    const [isOpen, setIsOpen] = useState();

    return (
        <Container>
            <PageTitle title='Formules' src={title} />
            <CardContainer width={width}>
                {formules.map((formule, index) =>
                    <FormuleCard key={index} {...formule} setIsOpen={setIsOpen} isOpen={isOpen} index={index} />
                )}
            </CardContainer>
        </Container>
    )
}

export default Formules;