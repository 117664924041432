import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Card, CardContent } from '@material-ui/core';
import Icon from './Icon';
import { animated, useSpring } from 'react-spring';
import { Link } from 'react-router-dom';

const Text = styled.p`
    margin-left: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
    line-height: 16px;
`;

const PriceText = styled(Text)`
    margin-left: 5px;
    font-size: 24px;
    line-height: 10px;
`;

const Title = styled.h2`
    color: #966fa9;
    margin-bottom: 10px;
    text-transform: uppercase;
    min-height: 70px;
`;

const PriceTextContainer = styled.div`
    color: black;
    display: flex;
    font-size: 20px;
    flex-flow: row nowrap;
    margin-top: 10px;
`;

const IconWithText = styled.div`
    display: flex;
    flex-flow: row nowrap;
    margin: 12px 0;
`;

const Footer = styled.div`
    border-top: 1px solid #8A7992;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 -10px 15px -10px #8A7992;
    height: 60px;
`;

const CardHeader = styled.div`
    margin-bottom: 20px;
    border-bottom: 1px solid #8A7992;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: pre-wrap;
    text-align: center;
`;

const StyledCard = styled(Card)`
    border: 2px solid #966fa9;
    background-color: rgba(153, 98, 173, 0.05) !important;
    margin: 10px;
    max-width: 400px;
    display: table;
`;

const List = styled.div`
    margin-top: 10px;
`;

const Description = styled.div`
    white-space: pre-wrap;
    text-align: justify;
`;

const Content = styled(animated.div)`
    display: flex;
    flex-flow: column nowrap;
    overflow: hidden;
`;

const Shadow = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 30px;
    cursor: pointer;
`;

const SubText = styled.div`
    margin-top: 10px;
    font-style: italic;
`;

const Arrow = styled(animated.div)`
`;

const Action = styled.button`
    width: auto;
    cursor: pointer;
    padding: 3px 5px;
    font-size: 16px;
    border-radius: 5px;
    background-color: #966fa9;
    color: white;
    border: none;
    &:hover {
        opacity: 0.7;
    }
`;

const FormuleCard = ({
    title,
    description,
    inclusive = [],
    exclusive = [],
    price,
    footer,
    isOpen,
    setIsOpen,
    index,
}) => {
    const isCardOpen = isOpen === index;
    const [contentHeight, setContentHeight] = useState(330);
    const descriptionRef = useRef();
    const listRef = useRef();
    const footerRef = useRef();
    const contentProps = useSpring({
        height: isCardOpen ? `${contentHeight}px` : '210px',
        config: { duration: 200 }
    });

    const arrowProps = useSpring({
        transform: isCardOpen ? 'rotate(180deg)' : 'rotate(0deg)',
        config: { duration: 200 }
    });

    useEffect(() => {
        if (descriptionRef?.current && listRef?.current) {
            setContentHeight(descriptionRef.current.clientHeight + listRef.current.clientHeight + footerRef.current.clientHeight + 30)
        }
    }, [descriptionRef, listRef, footerRef, isOpen])

    return (
        <StyledCard>
            <CardContent>
                <CardHeader>
                    <Title>{title}</Title>
                </CardHeader>
                <Content style={contentProps}>
                    <Description ref={descriptionRef} dangerouslySetInnerHTML={{ __html: description }} />
                    <List ref={listRef}>
                        {inclusive.map((i, index) =>
                            <IconWithText key={index}>
                                <Icon type='check' color='black' size='md' width='16px' />
                                <Text dangerouslySetInnerHTML={{ __html: i }} />
                            </IconWithText>
                        )}
                        {exclusive.map((i, index) =>
                            <IconWithText key={index}>
                                <Icon type='times' color='black' size='md' width='16px' />
                                <Text dangerouslySetInnerHTML={{ __html: i }} />
                            </IconWithText>
                        )}
                    </List>
                    <SubText ref={footerRef}>{footer}</SubText>
                </Content>
                <Shadow onClick={() => setIsOpen(index === isOpen ? null : index)}>
                    <Arrow style={arrowProps}>
                        <Icon type='chevron-down' size='lg' color='#966fa9' pointer={true} />
                    </Arrow>
                </Shadow>
                <Footer>
                    <PriceTextContainer>
                        {price ?
                            <>
                                <Icon type='euro-sign' color='black' size='md' width='16px' />
                                <PriceText>{price}</PriceText>
                            </> :
                            <PriceText>Prijs op aanvraag</PriceText>
                        }
                    </PriceTextContainer>
                    <Link to={{
                        pathname: '/contact',
                        state: {
                            formule: title
                        }
                    }}>
                        <Action>
                            Boek nu{' '}
                            <Icon type='chevron-right' size='sm' color='white' pointer={true} />
                        </Action>
                    </Link>
                </Footer>
            </CardContent>
        </StyledCard>
    )
}

export default FormuleCard;