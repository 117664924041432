import React from 'react';
import Container from './Container';
import title from '../images/about_title-min.png';
import PageTitle from './PageTitle';
import photo from '../images/about_photo.jpg';
import photoleft from '../images/about_photo_left.jpg';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useWindowSize } from '../useWindowSize';

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: ${p => p.width < 1050 ? 'justify-content' : 'space-around'};
    align-items: center;
`;

const TextContainer = styled.div`
    width: ${p => p.width < 1050 ? '70%' : '25%'};
    background-color: white;
    color: black;
    padding: 4% 8%;
    text-align: justify;
    margin-bottom: 30px;
`;

const TextTitle = styled.b`
    width: 100%;
    font-size: 20px;
    position: relative;
    overflow: visible;
    z-index: 1;
    :before {
        z-index: -1;
        content: '';
        width: ${p => p.width < 1050 ? '100%' : '250%'};
        position: absolute;
        top: 18px;
        left: 0;
        height: 10px;
        background-color: rgba(153, 98, 173, 0.15);
    }
`;

const Image = styled.img`
    width: 100%;
    border-radius: 2%;
    image-rendering: -webkit-optimize-contrast;
`;

const ContactColumn = styled.div`
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    width: ${p => p.width < 1050 ? '50%' : '20%'};
`;

const About = () => {
    const [width] = useWindowSize();

    return (
        <Container>
            <PageTitle title='About me' src={title} />
            <Wrapper widt={width}>
                {width > 1050 ?
                    <ContactColumn width={width}>
                        <Image src={photoleft} alt='Manon Vande Walle' />
                    </ContactColumn> :
                    null
                }
                <TextContainer width={width}>
                    <TextTitle width={width}>Wie ben ik?</TextTitle>
                    <br /><br />
                    <i>Ik ben Manon, aangenaam!</i> Ik ben 27, geboren en getogen in het West-Vlaamse Tielt en een echte social media addict. Tijdens mijn studentenjaren als rechtenstudente in Gent startte ik eind 2015 als blogger op Instagram. Op mijn pagina deel ik dagelijks mode- en lifestyle content met zo’n slordige vijftienduizend volgers. Wat begon als een hobby groeide uit tot een echte passie waarin ik volledig mijn creatieve kant kan loslaten.
                    <br /><br />
                    Door de jaren heen deed ik honderden samenwerkingen waarbij ik foto’s en video’s maakte voor talloze verschillende merken, winkels en producten. Omdat ik ook meer en meer vragen kreeg omtrent sociale media marketing en hoe dit alles precies werkt, ontstond stilaan het idee om mijn ervaring en kennis te delen met ondernemers die hun sociale media kanalen willen uitbouwen. Je kan er immers niet om heen: sociale media is een van de belangrijkste marketingtools van vandaag. Wil je er alles over leren van iemand met jaren ervaring? <b>I’m your girl!</b> Klik snel door naar <Link to={'/formules'}>mijn formules</Link> en lees er alles over.
                </TextContainer>
                <ContactColumn width={width}>
                    <Image src={photo} alt='Manon Vande Walle' />
                </ContactColumn>
            </Wrapper>
        </Container>
    )
}

export default About;