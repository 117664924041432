import React from 'react';
import styled from 'styled-components';
import { Card, CardContent } from '@material-ui/core';
import Icon from './Icon';
import { useWindowSize } from '../useWindowSize';

const ContactInfo = styled.div`
    width: 100%;
    margin-top: ${p => p.marginTop || p.width < 750 ? '5%' : '100px'};
    margin-bottom: 25px;
`;

const IconText = styled.span`
    margin-left: 10px;
`;

const MailText = styled.a`
    margin-left: 10px;
    color: rgba(0, 0, 0, 0.87) !important;
`;

const IconWithText = styled.div`
    display: flex;
    align-items: center;
    margin-top: 10px;
    width: 100%;
    overflow-wrap: anywhere;
`;

const StyledCard = styled(Card)`
    width: 100%;
    background-color: rgba(153, 98, 173, 0.15) !important;
`;

const ContactCard = ({ marginTop }) => {
    const [width] = useWindowSize();

    return (
        <ContactInfo width={width} marginTop={marginTop}>
            <StyledCard>
                <CardContent>
                    <IconWithText>
                        <Icon type='user' color='black' size='sm' />
                        <IconText>Mannie Media</IconText>
                    </IconWithText>
                    <IconWithText>
                        <Icon type='envelope' color='black' size='sm' />
                        <MailText href='mailto:manniemedia@outlook.be'>manniemedia@outlook.be</MailText>
                    </IconWithText>
                    <IconWithText>
                        <Icon type='map-marker-alt' color='black' size='sm' />
                        <IconText>Tielt, België</IconText>
                    </IconWithText>
                    <IconWithText>
                        <Icon type='briefcase' color='black' size='sm' />
                        <IconText>BE 0761.441.882</IconText>
                    </IconWithText>
                </CardContent>
            </StyledCard>
        </ContactInfo>
    )
}

export default ContactCard;