import React, { useState } from 'react';
import styled from 'styled-components';
import Container from './Container';
import title from '../images/portfolio_title-min.png';
import PageTitle from './PageTitle';
import PortfolioItem from './PortfolioItem';
import { useWindowSize } from '../useWindowSize';
import portfolios from '../porfolio.json'

const PortfolioContainer = styled.div`
    display: flex;
    width: ${p => p.width < 1050 ? '100%' : '65%'};
    flex-flow: row wrap;
    justify-content: space-around;
`;

const Portfolio = () => {
    const [openCard, setOpenCard] = useState();
    const [width] = useWindowSize();

    const handleCardClick = (index) => {
        setOpenCard(index)
    }

    return (
        <Container>
            <PageTitle title='Portfolio' src={title} />
            <PortfolioContainer width={width}>
                {portfolios?.map((portfolio, index) =>
                    <PortfolioItem {...portfolio} index={index} isOpen={index === openCard} handleCardClick={handleCardClick} />
                )}
            </PortfolioContainer>
        </Container>
    )
}

export default Portfolio;