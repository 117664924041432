import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import computer from '../images/logo-min.png';
import Background from './Background';
import Container from './Container';
import Icon from './Icon';
import MobileFeed from './MobileFeed';

const Row = styled.div`
    display: flex;
    flex-flow: row ${p => p.noWrap ? 'nowrap' : 'wrap'};
    justify-content: center;
    width: 100%;
`;

const WhoText = styled.div`
    width: 80%;
    text-align: center;
    color: black;
    font-size: 18px;
    z-index: 100;
    position: relative;
    :after {
        content: '';
        width: 80%;
        position: absolute;
        top: 16px;
        left: 10%;
        height: 12px;
        background-color: rgba(153, 98, 173, 0.15);
        z-index: -1;
    }
`;

const LogoContainer = styled.div`
    width: 40%;
    height: 100%;
    display: flex;
    align-items: center;
    margin-top: 50px;
`;

const Image = styled.img`
    width: ${p => p.width};
    background-color: transparent;
    image-rendering: -webkit-optimize-contrast;
    z-index: 100;
`;

const LintItem = styled.div`
    z-index: 100;
    margin-top: 70px;
    text-align: center;
    width: 250px;
    ${p => p.marginbottom && `margin-bottom: ${p.marginbottom}px`};
`;

const LintText = styled.div`
    margin-top: 15px;
    color: black;
`;

const TopTextContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-flow: column nowrap;
    justify-content: center;
    margin-top: 50px;
`;

const Title = styled.b`
    font-size: 54px;
    color: black;
    margin-bottom: 10px;
    text-align: center;
`;

const Lint = styled.div`
    min-height: 300px;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    overflow: hidden;
`;

const LintWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: flex-start;
    position: relative;
    overflow: hidden;
`;

const StyledLink = styled(Link)`
    color: rgb(0, 0, 0) !important;
`;

const TextTitle = styled.b`
    width: 100%;
    font-size: 20px;
`;

const Action = styled.button`
    font-family: 'Playfair Display', serif;
    width: auto;
    padding: 5px 25px;
    margin-top: 20px;
    cursor: pointer;
    font-size: 28px;
    border-radius: 5px;
    background-color: #966fa9;
    color: white;
    border: none;
    &:hover {
        opacity: 0.7;
    }
`;

const Name = styled.span`
    color: #966fa9;
`;

const Logo = styled.div`
    background-color: #F0E8F3;
    border-radius: 100%;
    display: flex;
    width: 100%;
    height: 100%;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    z-index: 100;
`;

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`;

const MobileHome = () => {
    return (
        <Container>
            <Wrapper>
                <Background />
                <Row>
                    <LogoContainer>
                        <Logo>
                            <Image src={computer} alt='Mannie media' width='100%' />
                        </Logo>
                    </LogoContainer>
                </Row>
                <Row>
                    <TopTextContainer>
                        <Title>Hey, ik ben <Name>Manon</Name></Title>
                        <WhoText>
                            <TextTitle><i>Social Media Coach</i></TextTitle>
                            <br />
                            <br />
                            Op zoek naar een <b>social media expert</b> die je op een duidelijke én toegankelijke manier wegwijs kan maken in de wereld van hashtags, followers en engagement?
                        </WhoText>
                        <Link to={'/formules'}>
                            <Action>Let's talk!</Action>
                        </Link>
                    </TopTextContainer>
                </Row>
            </Wrapper>
            <Row>
                <Lint>
                    <LintWrapper>
                        <LintItem>
                            <Icon type='tags' color='#966fa9' size='4x' />
                            <LintText>
                                Groeien op sociale media?
                                Bekijk hier alle <StyledLink to={'/formules'}>formules</StyledLink>.
                            </LintText>
                        </LintItem>
                        <LintItem>
                            <Icon type='camera-retro' color='#966fa9' size='4x' />
                            <LintText>
                                Nieuwsgierig naar wat ik zoal doe?
                                Bekijk mijn <StyledLink to={'/portfolio'}>portfolio</StyledLink> en lees enkele reviews.
                            </LintText>
                        </LintItem>
                        <LintItem marginbottom={70}>
                            <Icon type='envelope' color='#966fa9' size='4x' />
                            <LintText>
                                Meer weten?<br />
                                Contacteer mij <StyledLink to={'/contact'}>hier</StyledLink>.
                            </LintText>
                        </LintItem>
                    </LintWrapper>
                </Lint>
            </Row>
            <Row>
                <MobileFeed />
            </Row>
        </Container >
    )
}
export default MobileHome;