import React from 'react';
import styled from 'styled-components';

const WhoText = styled.div`
    width: 80%;
    text-align: center;
    color: black;
    font-size: 18px;
    z-index: 100;
    position: relative;
    :after {
        content: '';
        width: 80%;
        position: absolute;
        top: 16px;
        left: 10%;
        height: 12px;
        background-color: rgba(153, 98, 173, 0.15);
        z-index: -1;
    }
`;

const WhoContainer = styled.div`
    width: 580px;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    padding: 0 20px;
`;

const TextContainer = styled.div`
    background-color: rgba(153, 98, 173, 0.15);
    display: flex;
    width: 100%;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    z-index: 100;
    padding: 30px 0;
`;

const TextTitle = styled.b`
    width: 100%;
    font-size: 20px;
`;

const Feed = styled.div`
    .crt-grid-col3 {
        width: 95%;
        margin: 0 auto;
    }
`;

const MobileFeed = () => {
    return (
        <>
            <TextContainer>
                <WhoText>
                    <TextTitle><i>Wat kan ik voor jou doen?</i></TextTitle>
                    <br />
                    <br />
                    Zowel voor beginnende ondernemers als voor ervaren ondernemers zijn er verschillende coaching formules. Ben je een beginnende ondernemer, dan help ik graag bij het opzetten van je verschillende sociale media accounts en het uitwerken van een <b>consistente sociale media strategie</b>. Ben je een ondernemer die al een tijdje bezig is, dan maak ik voor jou een evaluatie van je bestaande socials en help ik je met mijn <b>tips and tricks</b> om deze verder uit te bouwen tot een social media succes! Sociale media hoeft immers geen uren per dag in beslag te nemen, als je het maar juist aanpakt. We focussen op creatieve manieren om jouw online aanwezigheid te vergroten en hierbij nieuwe, kwalitatieve volgers aan te trekken. <b>Are you in?</b>
                </WhoText>
            </TextContainer>
            <WhoContainer>
                {/* <Image src={feed} alt='Mannie media' width='70%' maxWidth={400} /> */}
                <Feed id="curator-feed-default-feed-layout">
                    {/* eslint-disable-next-line react/jsx-no-target-blank */}
                    <a href="https://curator.io" target="_blank" class="crt-logo crt-tag">Powered by Curator.io</a>
                </Feed>
            </WhoContainer>
        </>
    )
}
export default MobileFeed;